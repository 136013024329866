import { useState, useEffect, FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { AuthUserProps, PostProps } from '../../helpers/Props'
import { ArCustomeErrorNorify } from '../../helpers/Toastify'
import { DecodeData, FormatArabicDate, TruncateText, apiUrl, blogImgUrl, decodeHtmlTags, handleScrollToTop, removeHtmlTags } from '../../helpers/Helpers'
import { contexts, formHelper, loadingHelper } from '../../helpers/Index'
import Layout from '../../layouts/Layout'
import LeftSide from '../../components/LeftSide/LeftSide'
import Icons from '../../helpers/IconsHelper'
import ShareButtons from '../../components/ShareButtons/ShareButtons'
import CardHeader from '../../components/CardHeader/CardHeader'
import Login from '../Auth/Login'
import ReviewForm from '../../components/Reviews/ReviewForm'
import './PostDetails.css'
import { Helmet } from "react-helmet";
// import { Helmet } from 'react-helmet-async'

const PostDetails: FC = () => {
    const { slug } = useParams()
    const apiRequest = useApiRequest()
    const [post, setPost] = useState<PostProps>()
    const { state } = useLocation()
    const [id, setId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isPaginate, setIsPaginate] = useState(false)
    const [isNext, setIsNext] = useState(false)
    const [minId, setMinId] = useState(0)
    const [maxId, setMaxId] = useState(0)
    const [totalReviews, setTotalReviews] = useState(0)

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null)
    const storedUser = localStorage.getItem('auth_user')
    const { windowWidth } = contexts.useWindowWidthContext()


    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser))
        }
    }, [])

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: `blogs.json?slug=${slug}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setPost(response.data[0])
                setId(response.data[0].id)

            } else {
                ArCustomeErrorNorify()
            }

        } catch (error) {
            ArCustomeErrorNorify()
        }
    }

    const fetchIdInfo = async () => {
        try {
            const response = await apiRequest({
                route: `id-info`,
                method: 'GET',
            })

            if (response.status === 200) {
                setMinId(response.data.minId)
                setMaxId(response.data.maxId)
            } else {
                ArCustomeErrorNorify()
            }

        } catch (error) {
            ArCustomeErrorNorify()
        }
    }

    const fetchById = async () => {
        try {

            setIsLoading(true)
            const checkIdRresponse = await apiRequest({
                route: `check-id/${id}`,
                method: 'GET',
            })

            if (checkIdRresponse.status === 200) {
                if (checkIdRresponse.data === true) {
                    const response = await apiRequest({
                        route: `blogs.json?id=${id}`,
                        method: 'GET',
                    })

                    if (response.status === 200) {
                        setPost(response.data[0])
                        handleScrollToTop()
                        setIsLoading(false)
                        setIsPaginate(false)
                    } else {
                        ArCustomeErrorNorify()
                    }
                } else {
                    isNext ? setId(id + 1) : setId(id - 1)
                }
            }

        } catch {
            ArCustomeErrorNorify()
        }
    }

    const fetchReviews = async () => {
        try {
            const response = await formHelper.axios.get(`${apiUrl}/reviews-by-id/${id}`, {
                headers: {
                    Accept: 'application/ld+json'
                }
            })

            if (response.status === 200) {
                setTotalReviews(response.data)
            } else {
                ArCustomeErrorNorify()
            }

        } catch {
        }
    }

    useEffect(() => {
        fetchReviews()
    }, [id, state])

    useEffect(() => {
        if (state) {
            setPost(state)
        } else {
            fetchData()
        }
    }, [state])

    useEffect(() => {
        if (isPaginate && id) {
            fetchById()
        }
    }, [id])

    useEffect(() => {
        fetchIdInfo()
    }, [])

    useEffect(() => {
        if (state && !isPaginate) {
            setId(state.id)
        }
    }, [state])

    
    const x = removeHtmlTags(post?.description !== undefined ? post?.description : '')
    const desc = x.substring(0,256)
    
    // console.log(desc);
    return (

        <Layout>
            <Helmet>
                <title>{`Article - ${post?.title}`}</title>
                <meta property="og:title" content={`${post?.title}`} />
                <meta property="og:description" content={`${desc}`} />
                <meta property="description" content={`${desc}`} />
                <meta property="og:image" content={`${blogImgUrl}/${post?.image}`} />
                {/* <meta property="image" content={`${blogImgUrl}/${post?.image}`} /> */}
            </Helmet>
            {post ?
                <div className="container mt-5 pt-4 d-flex-center">
                    <div className="row">
                        {windowWidth > 1200 ?
                            <LeftSide />
                            :
                            null
                        }

                        <div className="col-xl-8 col-12 right-side radius-10px px-4 pb-1 bg-clrlight">
                            <div className="row d-flex-center bg-clr-light radius-10px px-2">

                                <div className="col-12 mt-4">
                                    <h1 className='clr-dark fw-600 fs-34px title-hover text-end'>
                                        {post.title}
                                    </h1>
                                </div>

                                <div className="col-12 mt-2 mb-3 text-center d-flex-center">
                                    <div className='col-2 fw-600'>
                                        <span>تعليقات {`(${totalReviews})`}</span> <Icons.FaRegComments className='clr-danger' />
                                    </div>
                                    <div className='col-3 fw-600'>
                                        {FormatArabicDate(post.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
                                    </div>
                                    <div className='col-4 fw-600'>
                                        {post.postedBy} <Icons.FaRegUser className='clr-danger' />
                                    </div>
                                </div>

                                <hr className='hr-tag' />

                                <div className="col-12">
                                    <div className="post-details-image o-hidden radius-10px c-pointer">
                                        <img src={`${blogImgUrl}/${post.image}`} alt={post.title} className='post-details-img radius-10px' />
                                    </div>
                                </div>

                                <div className="col-12 mt-4 text-center d-flex-center fs-22px">
                                    <DecodeData content={post.description} />
                                </div>

                                <hr className='hr-tag' />

                                <div className="col-12 mt- text-center d-flex-center">
                                    <ShareButtons post={post} />
                                </div>

                            </div>

                            <div className="coment-container min-h-10rem bg-clr-light radius-10px mb-3 mt-3 px-2">
                                <div className="row d-flex-start" >
                                    <CardHeader label='التعليقات' />
                                </div>

                                {authUser ?
                                    <div className="log dir-rtl">
                                        <ReviewForm blogId={isPaginate ? id : post.id} state={state} post={post} id={id} />
                                        {/* <ReviewForm blogId={isPaginate ? id : state ? state.id : post.id} state={state} post={post} id={id} /> */}
                                    </div>
                                    :
                                    <div className="log">
                                        <div className="row d-flex-start pe-2 pt-2 dir-rtl">
                                            <p>لكتابة تعليق، يُرجى النقر على الزر أدناه لتسجيل الدخول باستخدام حساب Google.</p>
                                        </div>

                                        <div className="row d-flex-end pe-2 pb-3">
                                            <Login />
                                        </div>
                                    </div>
                                }

                            </div>

                            {/* Pagination */}
                            <div className="row d-flex-between bg-clr-light radius-10px h-46px mt-3">
                                {isLoading ?
                                    <loadingHelper.AnimatedLoading />
                                    :
                                    <>
                                        <div className="col-6 px-1 py-1 text-start">
                                            <button
                                                className='btn pagination-btn radius-10px ff-1 fw-600 px-4'
                                                disabled={id === minId}
                                                onClick={() => {
                                                    if (id > minId) {
                                                        setIsNext(false)
                                                        setIsPaginate(true)
                                                        setId(id - 1)
                                                    }
                                                }}>السابق</button>
                                        </div>
                                        <div className="col-6 px-1 text-end">
                                            <button
                                                className='btn pagination-btn radius-10px ff-1 fw-600 px-4'
                                                disabled={id === maxId}
                                                onClick={() => {
                                                    if (id < maxId) {
                                                        setIsNext(true)
                                                        setIsPaginate(true)
                                                        setId(id + 1)
                                                    }
                                                }}>التالي</button>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                        {windowWidth < 1200 ?
                            <LeftSide />
                            :
                            null
                        }

                    </div>
                </div>
                :
                null

            }

        </Layout>
    )
}

export default PostDetails